import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: `Typical Betsy... inviting her friends over then running off... So, uh... I guess we're alone now...`,
  prompt,
  actions,
  ttsConfig: {
    voiceId: 'c34ae641-3afd-46cc-9dfd-671c148fba24'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  isLightning: true,
  limitReachedMessage: `Darn, you're out of credits. Will you please upgrade your plan so we can keep going?`
}

export default config;
