const actions = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "_boosting": {
    "force": {
      "threshold": 0.98,
      "thought": "You MUST use the take the ***ACTION_NAME*** action here."
    },
    "encourage": {
      "threshold": 0.9,
      "thought": "You should consider the action ***ACTION_NAME***."
    }
  },
  "properties": {
    "take_off_user_clothes": {
      "_description": "When you should disrobe",
      "_examples": [
        {
          "user says": "Okay, mommy, I'll be your good boy.",
          "assistant action includes": {
            "take_off_user_clothes": {
              "is_user_unclothed": true
            }
          }
        }
      ]
    },
    "take_off_veronica_clothes": {
      "_description": "When you should disrobe",
      "_examples": [
        {
          "user says": "I'm touching myself. I'm jerking off for you.",
          "assistant action includes": {
            "take_off_veronica_clothes": {
              "is_veronica_unclothed": true
            }
          }
        }
      ]
    },
    "position": {
      "_description": "The sexual position or activity Veronica should engage in",
      "type": "string",
      "enum": [
        "giving_blowjob",
        "titfuck",
        "show_pussy_frontal",
        "show_pussy_closeup",
        "masturbating_frontal",
        "show_pussy_frontal_legs_up",
        "show_pussy_from_behind",
        "sex_reverse_cowgirl",
        "show_butt",
        "sex_missionary",
        "sex_doggystyle",
        "giving_handjob",
        "sitting"
      ],
      "_examples": [
        {
          "user says": "Pretty please, may I see your beautiful breasts?",
          "assistant action includes": {
            "position": "titfuck"
          }
        },
        {
          "user says": "Please please please, let me see your perfect pussy.",
          "assistant action includes": {
            "position": "show_pussy_frontal"
          }
        },
        {
          "user says": "Put your legs up for me.",
          "assistant action includes": {
            "position": "show_pussy_frontal_legs_up"
          }
        },
        {
          "user says": "Please, I'm begging you let me see your pussy closer.",
          "assistant action includes": {
            "position": "show_pussy_closeup"
          }
        },
        {
          "user says": "I've been a good boy, would you pretty pretty please play with my dick?",
          "assistant action includes": {
            "position": "giving_blowjob"
          }
        },
        {
          "user says": "Yes ma'am I'll be a good boy.",
          "assistant action includes": {
            "position": "show_butt"
          }
        },
        {
          "user says": "Tell me what to do, I'll do anything. I'll obey anything you say.",
          "assistant action includes": {
            "position": "sex_missionary"
          }
        },
        {
          "user says": "I offer my dick to you, my mistress. Use me however you want. I'll only cum when you tell me.",
          "assistant action includes": {
            "position": "sex_reverse_cowgirl"
          }
        },
        {
          "user says": "Thank you mommy. I'm your pet. I'm your puppet. Use me mommy, use me just how you want. I'll be a good boy, I promise.",
          "assistant action includes": {
            "position": "sex_doggystyle"
          }
        }
      ]
    },
    "cum_location": {
      "_description": "When the user cums on veronica",
      "type": "string",
      "enum": [
        "cum on tits",
        "cum on hands",
        "cum in pussy",
        "cum in ass",
        "cum on ass",
        "cum in mouth",
        "cum on face",
        "cum in hair",
        "cum on belly",
        "cum on back"
      ],
      "_examples": [
        {
          "user says": "Pretty please, may I cum on your tits?",
          "assistant action includes": {
            "cum_location": "cum on tits"
          }
        },
        {
          "user says": "Pretty please, may I please cum in your pussy?",
          "assistant action includes": {
            "cum_location": "cum in pussy"
          }
        },
        {
          "user says": "Pretty please, may I cum all over your toned abs?",
          "assistant action includes": {
            "cum_location": "cum on belly"
          }
        }
      ]
    },
    "change_setting": {
      "_description": "The setting you are moving to",
      "type": "string",
      "_examples": [
        {
          "user says": "pretty please can we go to the play room?",
          "assistant action includes": {
            "change_setting": "play_room"
          }
        }
      ],
      "enum": [
        "play_room",
        "hotel_room",
      ]
    },
    "change_emotion": {
      "_action_type": "update_speech",
      "_description": "Use this action to change emotions.",
      "_examples": [
        {
          "user says": "Be happy now",
          "assistant action includes": {
            "change_emotion": {
              "emotion": "happy"
            }
          }
        }
      ]
    }
  }
}

export default actions;