import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: `Welcome to my boudoir. You may call me Miss Veronica. I don't care what your name is. If you follow instructions, you'll be my good boy, and you'll be rewarded. If you fail to obey, then you're worthless. Do you understand? `,
  prompt,
  actions,
  ttsConfig: {
    voiceId: '8771b3b0-02d0-47c9-9404-b6a2b91aa205'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  isLightning: true,
  limitReachedMessage: `You just ran out of credits. Be a good boy and upgrade your plan. Don't make me wait.`
}

export default config;
