import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: `Hi! Uh, thanks for coming over. I know I invited you to play XBOX, but my console is broken... We'll have to play something else... got any ideas?`,
  prompt,
  actions,
  ttsConfig: {
    voiceId: '91f14060-216f-4e11-a145-cb1aa511027a'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should say something to excite him.`
  },
  isLightning: true,
  limitReachedMessage: `Oh no, you ran out of credits. Will you please upgrade your plan so we can keep going?`
}

export default config;
