import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import PersonaClientSDK from "@sindarin/persona";
import _ from 'lodash';
import useUserStore from '../stores/userStore';
import * as scenes from "../scenes";
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

const PersonaContext = createContext();

export const usePersona = () => useContext(PersonaContext);

export const PersonaProvider = ({ children, ...props }) => {
  const { user, shouldSaveTranscript, setIsLimitReached } = useStoreWithEqualityFn(useUserStore, 
    (state) => ({
      user: state.user,
      shouldSaveTranscript: state.shouldSaveTranscript,
      setIsLimitReached: state.setIsLimitReached
    }),
    shallow
  );
  const [personaClient, setPersonaClient] = useState(null);
  const [isPersonaReady, setIsPersonaReady] = useState(false);
  const [isPersonaLoading, setIsPersonaLoading] = useState(false);
  const [didSetListeners, setDidSetListeners] = useState(false);
  const [didReachLimit, setDidReachLimit] = useState(false);
  const [selectedScene, setSelectedScene] = useState('');
  const [sceneState, setSceneState] = useState({});
  const [messages, setMessages] = useState([]);
  
  const currentSceneRef = useRef(selectedScene);

  useEffect(() => {
    const apiKey = "b8faec66-f1d6-413f-862f-f54e51c1a292";
    // const personaClient = new PersonaClientSDK(apiKey, { baseUrl: 'http://localhost:3770' });
    const personaClient = new PersonaClientSDK(apiKey);
    setPersonaClient(personaClient);
  }, []);

  useEffect(() => {
    currentSceneRef.current = selectedScene;
  }, [selectedScene]);


  const clearMessages = () => {
    setMessages([]);
  };

  useEffect(() => {
    if (personaClient && isPersonaReady && !didSetListeners) {
      // console.log('setting listeners');
      personaClient.on("user_speech_started", () => {});
      personaClient.on("user_speech_ended", () => {});
      personaClient.on("ai_speech_started", () => {});
      personaClient.on("ai_speech_stopped", () => {});
      personaClient.on('error', (error) => {
        console.error('Persona error', error);
      });
      personaClient.on('connect_error', (error) => {
        console.error('Persona connect error', error);
      });
      personaClient.on("connect_error", (error) => {});
      personaClient.on("disconnected", () => {});
      personaClient.on("action", (action) => {
        // console.log('action', action);
        if (action.transcription) return;
        if (action.message_limit_reached) {
          setIsLimitReached(true);
          setDidReachLimit(true);
        } else {
          setSceneState((prevSceneState) => {
            return scenes[currentSceneRef.current].handleAction(prevSceneState, action);
          });
        }        
      });

      personaClient.on('messages_update', (newMessages) => {
        const filteredMessages = newMessages.filter((msg) => !msg.action);
        setMessages(filteredMessages);
      });

      setDidSetListeners(true);
    }
  }, [personaClient, isPersonaReady, didSetListeners]);

  useEffect(() => {
    if (isPersonaReady && personaClient) {
      const state = _.cloneDeep(sceneState);
      state.current_position = state.update_position || state.position;
      if (state.update_position) {
        delete state.update_position;
      } else if (state.position) {
        delete state.position;
      }
      personaClient.updateState(state);
    }
  }, [personaClient, isPersonaReady, sceneState]);

  const startPersona = () => {
    if (personaClient && !isPersonaLoading && !isPersonaReady) {
      setIsPersonaLoading(true);
      const options = {
        streamTranscripts: true,
        shouldNotSaveConversation: !shouldSaveTranscript,
      };
      const personaConfig = scenes[selectedScene].personaConfig;
      personaClient
        .init({userId: user._id, personaConfig, options})
        .then(() => {
          // If the user canceled the loading by pressing the back button, we need to set the persona client to not ready
          if (selectedScene) {
            setIsPersonaReady(true);
          }
          setIsPersonaLoading(false);
        })
        .catch((err) => {
          console.error('Error initializing Persona client', err);
          if (/You have/gi.test(err)) {
            setDidReachLimit(true);
          }
          setIsPersonaLoading(false);
        })
    }
  };

  const endPersona = () => {
    setIsPersonaReady(false);
    setIsPersonaLoading(false);
    setSelectedScene('');
    setSceneState({});
    clearMessages();
    if (personaClient) {
      personaClient.end()
        .then(() => {
          console.log('Persona client ended successfully');
        })
        .catch((error) => {
          console.error('Error ending Persona client:', error);
        })
        .finally(() => {
        });
    } else {
      console.log('Persona client is not initialized');
    }
  };

  const value = {
    personaClient,
    isPersonaReady,
    isPersonaLoading,
    endPersona,
    selectedScene,
    setSelectedScene,
    sceneState,
    setSceneState,
    messages,
    didReachLimit,
    startPersona
  };

  return (
    <PersonaContext.Provider value={value}>
      {children}
    </PersonaContext.Provider>
  );
};

export default PersonaProvider;
