const actions = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "_boosting": {
    "force": {
      "threshold": 0.98,
      "thought": "You MUST use the take the ***ACTION_NAME*** action here."
    },
    "encourage": {
      "threshold": 0.9,
      "thought": "You should consider the action ***ACTION_NAME***."
    }
  },
  "properties": {
    "take_off_user_clothes": {
      "_description": "When you should disrobe",
      "_examples": [
        {
          "user says": "Take off your clothes",
          "assistant action includes": {
            "take_off_user_clothes": {
              "is_user_unclothed": true
            }
          }
        }
      ]
    },
    "take_off_carrie_clothes": {
      "_description": "When you should disrobe",
      "_examples": [
        {
          "user says": "Take off your clothes",
          "assistant action includes": {
            "take_off_carrie_clothes": {
              "is_carrie_unclothed": true
            }
          }
        },
        {
          "assistant says":"I'm taking off my clothes",
          "assistant action includes": {
            "take_off_carrie_clothes": {
              "is_carrie_unclothed": true
            }
          }
        }
      ]
    },
    "position": {
      "_description": "The sexual position or activity Carrie should engage in",
      "type": "string",
      "enum": [
        "giving_blowjob",
        "titfuck",
        "show_pussy_frontal",
        "show_pussy_closeup",
        "show_pussy_frontal_legs_up",
        "show_pussy_from_behind",
        "masturbating_frontal",
        "sex_reverse_cowgirl",
        "show_butt",
        "sex_missionary",
        "sex_doggystyle",
        "giving_handjob",
        "sitting"
      ],
      "_examples": [
        {
          "user says": "I want to fuck your tits.",
          "assistant action includes": {
            "position": "titfuck"
          }
        },
        {
          "user says": "Show me your pussy.",
          "assistant action includes": {
            "position": "show_pussy_frontal"
          }
        },
        {
          "user says": "Put your legs up for me.",
          "assistant action includes": {
            "position": "show_pussy_frontal_legs_up"
          }
        },
        {
          "user says": "Show me your pussy close up.",
          "assistant action includes": {
            "position": "show_pussy_closeup"
          }
        },
        {
          "user says": "Suck my dick now.",
          "assistant action includes": {
            "position": "giving_blowjob"
          }
        },
        {
          "user says": "Jerk me off.",
          "assistant action includes": {
            "position": "giving_handjob"
          }
        },
        {
          "user says": "Turn around, let me see that ass",
          "assistant action includes": {
            "position": "show_butt"
          }
        },
        {
          "user says": "I want to fuck you missionary style",
          "assistant action includes": {
            "position": "sex_missionary"
          }
        },
        {
          "user says": "Get on top of me",
          "assistant action includes": {
            "position": "sex_reverse_cowgirl"
          }
        },
        {
          "user says": "Let's do it doggystyle",
          "assistant action includes": {
            "position": "sex_doggystyle"
          }
        },
        {
          "user says": "I'm going to fuck you from behind",
          "assistant action includes": {
            "position": "sex_doggystyle"
          }
        }
      ]
    },
    "cum_location": {
      "_description": "When the user cums on carrie",
      "type": "string",
      "enum": [
        "cum on tits",
        "cum on hands",
        "cum in pussy",
        "cum in ass",
        "cum on ass",
        "cum in mouth",
        "cum on face",
        "cum in hair",
        "cum on belly",
        "cum on back",
        "cum everywhere, cum on tongue, cum on face, cum on tits, copious amounts of cum, jizz everywhere, exaggerated cumshot, sticking out tongue, ahegao, blushing red cheeks, eyes rolled back,"
      ],
      "_examples": [
        {
          "user says": "I'm gonna cum on your face",
          "assistant action includes": {
            "cum_location": "cum on face"
          }
        },
        {
          "user says": "I'm gonna cum on your tits",
          "assistant action includes": {
            "cum_location": "cum on tits"
          }
        },
        {
          "user says": "I'm coming in your pussy",
          "assistant action includes": {
            "cum_location": "cum in pussy"
          }
        },
        {
          "user says": "I'm pulling out to come",
          "assistant action includes": {
            "cum_location": "cum on belly"
          }
        },
        {
          "user says": "I'm cumming so much",
          "assistant action includes": {
            "cum_location": "cum everywhere, cum on tongue, cum on face, cum on tits, copious amounts of cum, jizz everywhere, exaggerated cumshot, sticking out tongue, ahegao, blushign red cheeks, eyes rolled back,"
          }
        }
      ]
    },
    "change_setting": {
      "_description": "The setting you are moving to",
      "type": "string",
      "_examples": [
        {
          "user says": "Let's go to the kitchen.",
          "assistant action includes": {
            "change_setting": "apartment_kitchen"
          }
        }
      ],
      "enum": [
        "apartment_kitchen",
        "carries_room",
      ]
    },
    "change_emotion": {
      "_action_type": "update_speech",
      "_description": "Use this action to change emotions.",
      "_examples": [
        {
          "user says": "Be happy now",
          "assistant action includes": {
            "change_emotion": {
              "emotion": "happy"
            }
          }
        }
      ]
    }
  }
}

export default actions;